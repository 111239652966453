<template>

  <div class="page-background" v-if="company">
    <div class="container mainPage">
      <div style="text-align: center;width: 300px">
        <img alt="shop-logo" class="shop-logo mb-3" v-bind:src="'https://api.aw-erp.com/v1/file/' + company.logo">
          <div class="form mb-3">
            <vue-tel-input class="form-control d-flex" :value="phone" @input="onInput" mode="international"></vue-tel-input>
          </div>

          <button :disabled="!phoneObj?.valid" @click="submit()" class="btn btn-secondary w-100">Submit</button>
      </div>
    </div>

    <footer>
        <div class="text-center p-4">
            Powered by
            <a class="text-reset fw-bold" href="https://awmenu.com/"><img style="max-width: 100px;" src="/images/logo/e-menu.png" alt="e-menu" /></a>
        </div>
    </footer>
  </div>
</template>

<script>
    import ApiClass from '@/services/api';
    import router from "@/router";
    import { VueTelInput } from 'vue3-tel-input'
    import 'vue3-tel-input/dist/vue3-tel-input.css'
    import {useRoute} from "vue-router";
    export default {
        name: 'LoginPage',
        props: {
            productId: String,
        },
        data() {
          return {
            phone: '',
            phoneObj: {},
            hash: '',
            api:{},
            company: {},
          };
        },
        components: {
          VueTelInput,
        },
        methods: {
          async submit() {
            if(!this.phoneObj.valid) {
              return;
            }
            // await router.push({name: 'loginOtp', params: {hash: this.hash}});
            //this.$emit('submit', this.phone)
            console.log(this.phoneObj);
            try {
              localStorage.setItem('nationalNumber',this.phoneObj.nationalNumber);
              localStorage.setItem('countryCallingCode', this.phoneObj.countryCallingCode);

              let res = await this.api.registerCustomer(
                    parseInt(this.phoneObj.nationalNumber),
                    parseInt(this.phoneObj.countryCallingCode) );

              if(res) {
                await router.push({name: 'loginOtp', params: {hash: this.hash}});
              }
            } catch {
              console.error('Error Register User');
            }
          },
          onInput(phone, phoneObject) {
            if (phoneObject?.formatted) {
              this.phone = phoneObject.formatted
              this.phoneObj = phoneObject

              if(this.phoneObj.valid) {
                this.error = '';
              }
            }
          },
          login() {
            router.push({ name: 'login', params: { hash: this.hash } });
          },
          viewStore() {
            router.push({ name: 'categories', params: { hash: this.hash } });
          },
        },
        async mounted() {
          try {
            let companyRes = await this.api.fetchCompany();
            this.company = companyRes.data?.result;
          } catch {
            console.error('Error fetching Shop Data');
          }

          const searchElement = document.getElementById('search');

          if (searchElement) {
            searchElement.focus();
          }
        },

        created() {
            const route = useRoute();
            this.hash = route.params.hash;
            this.api = new ApiClass(this.hash);
        },
    }
  </script>
  

<style scoped>
  @import '../assets/css/main.css';

  .mainPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90dvh;
  }



  input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
</style>