<template >
  <NavBar :allowShare="false" :profile="true"  :cart="true" :back="true" :title="orderTitle"></NavBar>

  <div v-if="load" class="px-0 bg-white">
    <div class="container p-3" style="font-size: small">
      <div class="row">
        <div class="col-12">
          <h5>Order Details</h5>
        </div>
      </div>


      <div class="row border-bottom py-2"  >
        <div  class="col-3">
          Type
        </div>
        <div class="col-9 ">
          {{ order.orderType}}
        </div>
      </div>

      <div class="row border-bottom py-2"  >
        <div  class="col-3">
          Status
        </div>
        <div class="col-9 " :class="'text-'+order.status_class">
         {{ order.status }}
        </div>
      </div>

      <div class="row border-bottom py-2"  >
        <div  class="col-3">
          Order date
        </div>
        <div class="col-9">
          {{order.createdAt}}
        </div>
      </div>

      <div class="row border-bottom py-2"  >
        <div  class="col-3">
          Mobile
        </div>
        <div class="col-9">
          {{order.menuContact?.dialingCode}} {{order.menuContact?.mobile}}
        </div>
      </div>

      <div class="row border-bottom py-2"  >
        <div  class="col-3">
          Address
        </div>
        <div class="col-9">
            {{order?.order_addresses[0]?.block}}
            {{order?.order_addresses[0]?.area}}
            {{order?.order_addresses[0]?.roadNo}}
            {{order?.order_addresses[0]?.buildingNo}}
            {{order?.order_addresses[0]?.flatNo}}
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <h5>Order Summary</h5>
        </div>
      </div>

      <div class="row border-bottom  py-2"  v-for="(orderItem) in this.order?.order_items" :key="orderItem.id">
        <div class="col-2  text-start">{{orderItem.qty}} <span class="orderDetailsCCY">x</span></div>
        <!--div class="col-1 p-0">
          <img class="max-width-100 max-height-100" v-bind:src="'https://api.aw-erp.com/v1/file/' + orderItem.item.featuredImage" />
        </div-->
        <div class="col-7 p-0 text-limit">{{orderItem.item.name}}</div>
        <div class="col-3 p-0"><span class="orderDetailsCCY">BD</span> {{orderItem.subTotal}}</div>
      </div>

      <div class="mt-5 row  py-2">
        <div class="col-9 text-end">
          Sub Total
        </div>
        <div class="col-3 text-start p-0">
          <span class="orderDetailsCCY">BD</span> {{this.order.subTotal}}
        </div>
      </div>
      <div class="row py-2">
        <div class="col-9 text-end ">
          Tax
        </div>
        <div class="col-3 text-start p-0">
          <span class="orderDetailsCCY">BD</span> {{this.order.tax}}
        </div>
      </div>

      <div class="row text-success py-2 fw-bold ">
        <div class="col-9 text-end  ">
          Total
        </div>
        <div class="col-3 text-start p-0">
          <span class="orderDetailsCCY">BD</span> {{this.order.total}}
        </div>
      </div>
    </div>

  </div>

  <LoadingIndicator v-else :loading="!load"></LoadingIndicator>

</template>

<script>
import ApiClass from '@/services/api';
import NavBar from "@/components/NavBar.vue";
import LoadingIndicator from "@/components/Loading.vue";
import {useRoute} from "vue-router";

export default {
  name: 'OrderDetails',
  data() {
    return {
      orderTitle: 'Order #',
      orderId: 0,
      load: false,
      order: {},
      timer: null,
      api: null,
      hash: null,
    };
  },
  components: { LoadingIndicator, NavBar},
  props: [],
  methods: {
    async autoFetch() {
      await this.fetchOrder();

      if(['pending_approval','readyToPickUp','underDelivery'].includes(this.order?.status))
      {
        this.timer = setTimeout(  () => {
          this.fetchOrder();
        }, 60000)
      }

    },
    async fetchOrder() {
        try {
          let apiResponse = await this.api.fetchOrder(this.orderId);

          if(!this.load) {
            this.load = true;
          }
          this.order = apiResponse.data?.result;

          const d = new Date(this.order.createdAt);
          this.order.createdAt = d.toLocaleString("en-US");

            switch (this.order.status) {
              case 'pending_approval':
                this.order.status = 'Pending Approval';
                this.orders.status_class = 'warning';
                break;
              case 'accepted':
                this.order.status = 'Accepted';
                this.order.status_class = 'info';
                break;
              case 'readyToPickUp':
                this.order.status = 'Ready to pickup';
                this.order.status_class = 'info';
                break;
              case 'underDelivery':
                this.order.status = 'Under delivery';
                this.order.status_class = 'info';
                break;
              case 'delivered':
                this.order.status = 'Delivered';
                this.order.status_class = 'success';
                break;
              case 'completed':
                this.order.status = 'Completed';
                this.order.status_class = 'success';
                break;
              case 'rejected':
                this.order.status = 'Rejected';
                this.order.status_class = 'danger';
                break;
              case 'canceled':
                this.order.status = 'Canceled';
                this.order.status_class = 'danger';
                break;
              default :
                this.order.status = 'Error';
                this.order.status_class = 'info';
                break;
            }
        } catch (error) {
          console.error('Error Order:', error);
          this.load = true;
        }
    },
  },
  created() {
    const route = useRoute();
    this.orderId = route.params.id;
    this.orderTitle += this.orderId;
    this.hash = route.params.hash;
    this.api = new ApiClass(this.hash);

    this.autoFetch();
  },
}
</script>

<style scoped>

@import './../assets/css/style.css';

</style>
