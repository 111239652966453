<template>
  <!-- Here assets start-->

  <!-- Here assets end-->

    <div id="main" class="page-background default-font">
        <div class="container-fluid p-1 pt-2 text-white text-center bg-header" >
            <NavBar :back="true" title="New Address"></NavBar>
        </div>

        <div class="product-list-continer bg-white mt-4">
          <div id="map">
            <!--In the following div the HERE Map will render-->
            <div id="mapContainer" style="height:360px;width:100%" ref="hereMap"></div>
          </div>

          <form class="form container" @submit="saveAddress">
            <div class="row">
              <div class="col-12">
                <div class="form-floating mb-3">
                  <input v-model="address.aliasName" type="text" class="form-control" id="floatingInputName" required>
                  <label for="floatingInputName" >Address name</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-floating mb-3">
                  <input v-model="address.area" type="text" class="form-control" id="floatingInputRoadNo" required>
                  <label for="floatingInputRoadNo" >Area</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-floating mb-3">
                  <input v-model="address.roadNo" type="text" class="form-control" id="floatingInputRoadNo" required>
                  <label for="floatingInputRoadNo" >Road No</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-floating mb-3">
                  <input v-model="address.buildingNo" type="text" class="form-control" id="floatingInputRoadNo" required>
                  <label for="floatingInputRoadNo" >Building No \ House No</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-floating mb-3">
                  <input v-model="address.block" type="text" class="form-control" id="floatingInputRoadNo" required>
                  <label for="floatingInputRoadNo" >Block</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-floating mb-3">
                  <input v-model="address.mobileNo" type="tel" class="form-control" id="floatingInputRoadNo" >
                  <label for="floatingInputRoadNo" >Mobile</label>
                </div>
              </div>
            </div>

            <div>
              <div class="form-floating mb-3">
                <input v-model="address.description" type="text" class="form-control" id="floatingInputRoadNo" >
                <label for="floatingInputRoadNo" >Description</label>
              </div>
            </div>

            <div>
              <button type="submit" class="btn btn-primary w-100" >Add</button>
            </div>
          </form>
        </div>
    </div>
  </template>
  
  <script>

  import NavBar from "@/components/NavBar.vue";
  import ApiClass from "@/services/api";
  import router from "@/router";
  import {useRoute} from "vue-router";


  export default {
    name: 'NewAddress',
    components:{NavBar},
    props: {
      center: Object
      // center object { lat: 40.730610, lng: -73.935242 }
    },
    data() {
      return {
        hash: null,
        api: null,
        latitude: null,
        longitude: null,
        address: {
          aliasName: "",
          block: "",
          area: "",
          roadNo: "",
          dialingCode: 973,
          mobileNo: '',
          buildingNo: "",
          flatNo: "",
          description: "",
          country: "BH",
          locationLongitude: 20.5545,
          locationLatitude: 50.7552
        },
        platform: null,
        apikey: "Y6uAhnpuPdR5gABRdlDY53tA5C56hfZkhACUqZIrANc"
        // You can get the API KEY from developer.here.com
      };
    },
    async created() {
      const route = useRoute();
      this.hash = route.params.hash;
      this.api = new ApiClass(this.hash);

      // let script = document.createElement('script');
      // script.setAttribute('src', 'https://js.api.here.com/v3/3.1/mapsjs-core.js')
      // await document.head.appendChild(script);
      // script.setAttribute('src', 'https://js.api.here.com/v3/3.1/mapsjs-service.js')
      // await document.head.appendChild(script);
      // script.setAttribute('src', 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js')
      // await document.head.appendChild(script);
      // script.setAttribute('src', 'https://js.api.here.com/v3/3.1/mapsjs-ui.js')
      // await document.head.appendChild(script);
      // script.setAttribute('src', 'https://js.api.here.com/v3/3.1/mapsjs-clustering.js')
      // await document.head.appendChild(script);
      // script.setAttribute('src', 'https://js.api.here.com/v3/3.1/mapsjs-data.js')
      // await document.head.appendChild(script);
      //

    },
    async mounted() {


      // Initialize the platform object:
      const platform = new window.H.service.Platform({
        apikey: this.apikey
      });
      this.platform = platform;
      this.address.mobileNo = localStorage.getItem('nationalNumber');
      this.initializeHereMap();
    },
    methods: {
      async saveAddress(e) {
        e.preventDefault();
        this.address.mobileNo = parseInt(this.address.mobileNo);
        await this.api.saveAddress(this.address);
        await router.push({name: 'cart', params:{hash: this.hash, setAddress: 1}});
      },
      getAddress(latitude, longitude) {
        let apiAddressUrl = `https://revgeocode.search.hereapi.com/v1/revgeocode?lang=en-US&at=${latitude},${longitude}&apiKey=${this.apikey}`;

        this.address.locationLongitude = longitude;
        this.address.locationLatitude = latitude;
        let selectedAddress = this.address;

        fetch(apiAddressUrl)
            .then(response => response.json())
            .then(data => {
              const address = data.items[0].address;
              console.log(address);

              selectedAddress.aliasName = address.label;
              selectedAddress.area = address.city;
              selectedAddress.block = address.district;
              selectedAddress.roadNo = address.street;

            })
            .catch(error => {
              console.error('Error fetching address:', error);
            });

        this.address = selectedAddress;
      },
      async initializeHereMap() { // rendering map

        const mapContainer = this.$refs.hereMap;
        const H = window.H;
        // Obtain the default map types from the platform object
        let maptypes = this.platform.createDefaultLayers();

        // Instantiate (and display) a map object:
        let map = new H.Map(mapContainer, maptypes.vector.normal.map, {
          zoom: 10,
          center: this.center
        });

        addEventListener("resize", () => map.getViewPort().resize());
        // add behavior control
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        // add UI
        H.ui.UI.createDefault(map, maptypes);

        let latitude = 26.103421566409253, longitude= 50.54833746388567;
        if (Object.prototype.hasOwnProperty.call(navigator, 'geolocation') &&   navigator.geolocation.getCurrentPosition) {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });

          latitude = position.coords.latitude;
          longitude = position.coords.longitude;


        } else {
          console.error('permission denied for your location.');
        }

          // Center the map to the user's current location
          map.setCenter({ lat: latitude, lng: longitude });
          map.setZoom(18); // Optionally, adjust the zoom level

          this.getAddress(latitude,longitude);

          // Add a draggable marker at the user's current location
          const marker = new H.map.Marker({ lat: latitude, lng: longitude }, { volatility: true });
          marker.draggable = true;

          // Handle drag start event
          marker.addEventListener('dragstart', () => {
            console.log('Drag start');
          });


          // Handle drag move event
          marker.addEventListener('drag', (event) => {
            const newPosition = map.screenToGeo(
                event.currentPointer.viewportX,
                event.currentPointer.viewportY
            );

            event.stopPropagation();
            marker.setGeometry(newPosition);

            console.log(`New marker position: ${newPosition.lat}, ${newPosition.lng}`);

          });

            // Handle drag end event
            marker.addEventListener('dragend', (event) => {
              const newPosition = map.screenToGeo(
                  event.currentPointer.viewportX,
                  event.currentPointer.viewportY
              );
              map.setCenter(newPosition);
              console.log(`Marker dropped at: ${newPosition.lat}, ${newPosition.lng}`);

              this.getAddress(newPosition.lat,newPosition.lng);
            });

            map.addObject(marker);


        // End rendering the initial map
      }
    },

  }
  </script>
  
  <style scoped>

    @import './../assets/css/style.css';

    #map {
      width: 60vw;
      min-width: 360px;
      text-align: center;
      margin: 5% auto;
      background-color: #ccc;
    }
  </style>
  