<template>
  <div id="productDetails"  v-if="product" v-cloak class="page-background default-font">
    <div class="container-fluid p-1 pt-2 text-white text-center bg-header" >
      <NavBar  ref="navbarRef" :allowShare="true" :profile="true" :back="true" :title="translate(product.name,product.nameL2)" :cart="true"></NavBar>
    </div>

    <div class="mt-2 ">
      <img v-bind:src="'https://api.aw-erp.com/v1/file/' + product.featuredImage"
           class="item-image  " alt="...">
    </div>

    <div  >
      <div class="product-list-continer bg-white product-details p-4">
        <div class="row">
          <div class="col">

            <button class="btn-add-to-cart" v-if="parseInt(product.qty) > 0" @click="addToCart(product.id)" >
              Add +
            </button>

            <div class="item-stock-label" v-else >
                <span class="item-stock-text-danger" >Run out</span>
            </div>
          </div>
          <div class="col item-price m-0">
              <div class=" text-end " >
                <small>BD</small> {{product.price}}
              </div>
            <div class="col item-tax-label text-end m-0">
                <span v-if="product.tax_included == 1">VAT inclusive</span>
                <span v-else>VAT exclusive</span>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="item-description"  v-html="translate(product.description,product.descriptionL2)" />
        </div>

      </div>


    </div>
  </div>
</template>
  
  <script>
    import ApiClass from '../services/api';
    import { useRoute } from 'vue-router';
    import NavBar from "@/components/NavBar.vue";
    import router from "@/router";

    export default {
      name: 'ProductDetails',
      components: {NavBar},
      data() {
          return {
            hash: '',
            api: {},
            product: null,
            productId: null,
            lang: 'en',
          }
      },
      methods: {
        async addToCart(id) {
          try {

            let authToken = localStorage.getItem('authToken');
            if(authToken === null || authToken === '') {
              await router.push({ name: 'login', params: { hash: this.hash } });
            }
            else {
              let res = await this.api.addToCart(id,1);
              if(res) {
                this.$refs.navbarRef.incCartCount();
              }
            }

          } catch (e){
            console.error(e)
            console.error('Error Adding Item to Cart');
          }
        },
        formatDate (date)  {
          return date.substr(0,date.search('T'));
        },
        translate (enVar, arVar) {
          if(this.lang === 'ar' && arVar != null) {
            return arVar;
          }
          return enVar;
        },
        goBack() {
          return history.back();
        }
      },
      async created() {
        const route = useRoute();
        this.productId = route.params.id;
        this.hash = route.params.hash;
        this.api = new ApiClass(this.hash);

        try {
            const response = await this.api.fetchProductDetails(this.productId);
            this.product = response.data?.result;
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
      },
    }
  </script>

<style scoped>
.product-details {
  min-height: 48vh;
}

</style>

<style>


.item-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.item-price {
    color: #2B2B2B;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.item-tax-label {
    color: #B0B0B6;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.item-stock-label {
    border-radius: 50px;
    background: rgba(176, 176, 182, 0.29);
    width: 65.172px;
    height: 21px;
    flex-shrink: 0;
    font-size: 10px;
    padding-top: 5px;
    text-align: center;
    font-weight: 400;
    line-height: normal;
}

.item-stock-text-success {
    color: #4DB751;
}

small {
    font-size: 10px;
}

.item-description {
  line-height: 1;
  font-size: small;
}

#productDetails .item-description p {
  margin-bottom: 5px;
}

.item-stock-text-danger {
    color: #b74d70;
}

</style>