<!--<template>-->
<!--  <div id="main" class="page-background default-font">-->
<!--    <div class="container-fluid p-1 pt-2 text-white text-center bg-header" >-->
<!--      <NavBar :back="true" title="My Profile"></NavBar>-->
<!--    </div>-->
<!--    <div class="container  px-0" v-if="profile?.id">-->
<!--      <div class="row d-flex justify-content-center">-->
<!--        <div class="col-md-12 mt-4">-->

<!--          <div class="card" style="border-radius: 15px;">-->
<!--            <div class="card-body text-center">-->





<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<template>


  <div id="main" class="page-background default-font">
    <div class="container-fluid p-1 pt-2 text-white text-center bg-header" >
      <NavBar :back="true" title="My Profile" :cart="false"></NavBar>
    </div>
    <div v-if="load" class="container  px-0 mt-4" id="items">
      <div class="product-list-continer bg-white p-4">
        <div class="mt-3 mb-4">
          <div class="avatar">
            <span v-if="profile.name?.length >0" class="m-auto">{{profile.name[0]}}</span>
            <span v-else class="m-auto"><font-awesome-icon class="avatar" :icon="['fa', 'user']"  style="width: 35px;height: auto" /></span>
          </div>
        </div>
        <p class="text-muted mb-4">
          <span>+{{profile.dialingCode}}</span> <span>{{profile.mobile}}</span>
        </p>

        <form class="form" @submit="updateUserProfile">
          <div class="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="male" v-model="profile.gender">
              <label class="form-check-label" for="inlineRadio1">male</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="female" v-model="profile.gender">
              <label class="form-check-label" for="inlineRadio2">female</label>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input v-model="profile.name" type="text" class="form-control" id="floatingInputName" required>
            <label for="floatingInputName" >Name</label>
          </div>

          <div class="form-floating mb-3">
            <input v-model="profile.birthDay" type="date" class="form-control" id="floatingInputBirthday" placeholder="Birthday" required>
            <label for="floatingInputBirthday" >Birthday</label>
          </div>

          <div>
            <button type="submit" class="btn btn-primary w-100" >Save</button>
          </div>
        </form>
      </div>
    </div>

    <!----- loading indicator ------------->
    <div v-if="!load" class="container container-loading-indicator bg-white">
      <div class="row align-items-center row-loading-indicator">
        <div class="col text-center align-middle ">
          <img  style="max-width: 50px;" src="/images/logo/spin-logo.gif" class="mt-5"  alt="loading ..."/>
        </div>
      </div>
    </div>
    <!------------------------------------->
  </div>

</template>

<script>
  import NavBar from "@/components/NavBar.vue";
  import ApiClass from '../services/api';
  import {useRoute} from "vue-router";

  export default {
    name: 'MyProfile',
    components: {NavBar},
    data() {
      return {
        load: false,
        hash: null,
        api: null,
        profile: {
          id: null,
          dialingCode: null,
          mobile: null,
          name: null,
          birthDay: null,
          gender: null
        }
      }
    },
    props: {
    },
    async created() {
      const route = useRoute();
      this.hash = route.params.hash;
      this.api = new ApiClass(this.hash);
      await this.getUserProfile();
    },
    methods: {
      async getUserProfile() {
        try {
          let response = await this.api.fetchProfile();
          this.profile = response.data?.result;
        }
        catch (e){
          console.error('failed to fetch profile');
          console.error(e);
        }
        this.load = true;
      },
      async updateUserProfile(e) {
        e.preventDefault();
        try {
          let response = await this.api.updateProfile(this.profile);
          this.profile = response.data?.result;
          this.$notify({
            title: "Updated",
            text: `User profile updated`,
          });
          this.goBack();
        }
        catch (e){
          console.error('failed to fetch profile');
          console.error(e);
        }

      },
      goBack() {
        return history.back();
      }
    }
  }
</script>

<style scoped>

.avatar {
  width: 75px;
  height: 75px;
  background-color: #1a1d20;
  color: white;
  display: flex;
  align-content: center;
  border: 1px solid #1a1d20;
  border-radius: 50%;
  font-size: 25px;
}

</style>