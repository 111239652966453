<template>
    <div id="main" class="page-background ">
            <NavBar  ref="navbarRef" :profile="true" :back="true" title="Menu" :cart="true"></NavBar>
            <SearchBar :searchItems="searchItems" :keydownFunction="() => {load =true;}" :text="this.searchtext"></SearchBar>
<!--            <TagsBar :setTags="setTags" :tags="tags"></TagsBar>-->
            <div class="px-0 m-0 bg-white" id="items">
                <div v-if="items && items.length>0">
                    <div  v-for="item of items" :key="item.id" class="product-container" >
                      <div @click="viewItem(item)"  class="clickable product-image col-md-2 col-4 center-item p-2">
                        <img class="img-fluid" style="border-radius: 15px;" v-bind:src="item.imgSrc" @error="item.imgSrc = 'https://qr.aw-erp.com/images/default/emenu-invalid.png'" />
                      </div>
                      <div class="product-details col p-2">
                        <div @click="viewItem(item)"  class="clickable text-limit" style="color: #606060; font-size: 14px; font-weight: 700; word-wrap: break-word">
                          {{translate(item.name,item.nameL2)}}
                        </div>
                        <p v-html="translate(item.description,item.descriptionL2)" class="product-description text-limit" style="height: 33px;color: #B0B0B6; font-size: 12px;  font-weight: 400;"></p>
                        <div class="product-footer" style="display: flex;">
                          <div class="product-price" style="flex:0 1 50%">
                            <span style="color: #2B2B2B; font-size: 12px;  font-weight: 700; word-wrap: break-word">BD</span>
                            <span style="padding-left: 3px;color: #2B2B2B; font-size: 20px; font-weight: 700; word-wrap: break-word"></span>
                            <span style="color: #2B2B2B; font-size: 18px;  font-weight: 700; word-wrap: break-word">{{item.price}}</span>
                            <div style="padding-left: 20px;line-height: 0;padding-bottom: 8px;color: #B0B0B6; font-size: 8px; font-weight: 700; word-wrap: break-word">
                              <span v-if="item?.tax_included === 1">VAT included</span>
                              <span v-else>VAT inclusive</span>
                            </div>
                          </div>
                          <div class="product-add-to-cart"  style="flex:0 1 50%;text-align: right;">
                            <button v-if="parseInt(item.qty) > 0" @click="addToCart(item.id)" class="btn-add-to-cart" style="">
                                Add +
                            </button>
                            <button v-else @click="addToCart(item.id)" class="btn-add-to-cart text-danger disabled" disabled>
                              Run out
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div  v-if="load && (!items || items.length < 1)"  class="p-3 bg-white text-center">
                  <h6>{{translate('no result found!','لم يتم العثور على نتائج')}}</h6>
                </div>
            </div>

      <LoadingIndicator :loading="!load" :title="loadingTitle"></LoadingIndicator>
        </div>

  </template>
  
  <script>
    import { ref } from 'vue'; // Import toRefs
    import ApiClass from '@/services/api';
    import { useRoute } from 'vue-router'; // Import useRoute
    import NavBar from "@/components/NavBar.vue";
    import SearchBar from "@/components/SearchBar.vue";
    //import TagsBar from "@/components/TagsBar.vue";
    import router from "@/router";
    import LoadingIndicator from "@/components/Loading.vue";

    const searchDelay = 600;
    let timer;
  export default {
    name: 'ProductsList',
    components: {LoadingIndicator, SearchBar, NavBar},
    data() {
      return {
        categoryId: '',
        items: [],
        tags: [],
        selectedTag: [],
        searchtext: '',
        loadingTitle:'',
        hash: '',
        api:{},
      }
    },
    methods: {
      getTopParentFooterRef() {
        let parent = this.$parent;
        while (parent.$parent) {
          parent = parent.$parent;
        }
        return parent.$refs.footerRef;
      },
      async addToCart(id) {
        try {

          let authToken = localStorage.getItem('authToken');
          if(authToken === null || authToken === '') {
            await router.push({ name: 'login', params: { hash: this.hash } });
          }
          else {
            let res = await this.api.addToCart(id,1);
            if(res) {
              this.getTopParentFooterRef().fetchCart();
            }
          }

        } catch (e){
          console.error(e)
          console.error('Error Adding Item to Cart');
        }
      },
      async searchItems(input,tags, removeDelay) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }

        const search = async () => {

          try {
            this.searchtext = input;
            if(this.searchtext === '' && this.categoryId === '') {
              await router.push({name: 'categories', params: {hash: this.hash}});
            }
            else {
              let products = await this.api.fetchProducts(this.categoryId,input,tags ?? []);
              let items = products.data?.result?.items;

              for await (let item  of items) {
                item.imgSrc = 'https://api.aw-erp.com/v1/file/' + item.featuredImage;
              }

              this.items = items;

              this.tags = products.data?.result?.tags ?? [];
              this.tags.forEach(t => {
                if(this.selectedTag.includes(t.id) ){
                  t['active'] = true;
                }
              });
            }

          } catch (error) {
            console.error('Error company product details:', error);
          }
          this.load = true;
        };

        if(removeDelay) {
          await search();
        }
        else {
          this.load = false;
          this.loadingTitle = 'searching';
          timer = setTimeout(search, searchDelay);
        }
      },
      async loadProducts() {
        this.load = false;
        try {

          const route = useRoute();
          this.searchtext = route.params.search ?? '';

          this.categoryId = route.params.catId ?? '';

          let products = await this.api.fetchProducts(route.params.catId,this.searchtext,'');


          for await (let item  of products.data?.result?.items) {
            item.imgSrc = 'https://api.aw-erp.com/v1/file/' + item.featuredImage;
          }

          this.items = products.data?.result?.items;
          this.tags = products.data?.result?.tags;

        } catch (error) {
          console.error('Error company product details:', error);
        }

        const searchElement = document.getElementById('search');

        if (searchElement && this.searchtext?.length > 0) {
          searchElement.focus();
        }

        this.load = true;
      },
      viewItem(item) {
        router.push({ name: 'productDetails', params: { hash: this.hash, id: item?.id } });
      }
    },
    created() {
      const route = useRoute();
      this.hash = route.params.hash;
      this.api = new ApiClass(this.hash);

      this.loadProducts();

    },
    setup() {
        const lang = 'en';
      const load = ref(false);


        const translate = (enVar, arVar) => {
            if(lang === 'ar' && arVar != null) 
                return arVar;
            return enVar; 
        };

        const goBack = () => {
            router.back();
        }

        return { 
            translate,
            goBack,
            lang,
            load,
        };
    },
  }
  </script>
  
  <style scoped>

    @import './../assets/css/style.css';

    
   
    .item-page-background {
        background: #F2F2F2;
        height: 100vh;
        font-family: 'MADE TOMMY', 'Cairo',sans-serif;
    }

    .search {
        background: rgba(176, 176, 182, 0.20);
        border-radius: 50px;
        height: 46px;
        position: relative;
        border:0;
        margin-left: 0;
        padding-left: 18px;;
        
    }

    .rectangle-3, .rectangle-3 * {
        box-sizing: border-box;
    }

    .rectangle-3 {
    background: var(--blurgrey, #f0f0f0);
     border-radius: 10px;
     width: 335px;
     height: 1px;
     position: absolute;
     left: 29px;
    top: 365px;
    }
    
  </style>
  