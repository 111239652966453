<template>
  <div class="container py-3">
    <div class="row d-flex">
      <div class="col">
        <div class="d-flex" style="align-items: center;">
          <span v-if="props.back" class="text-dark" id="back-btn" @click="goBack()" style="cursor: pointer">
            <font-awesome-icon  :icon="['fas', 'arrow-left']" />
          </span>
          <h1 class="header-title m-0 ms-2">{{ props.title }}</h1>
        </div>
      </div>
      <div class="col-2 d-flex justify-content-end" style="cursor: pointer">
        <div  v-if="props.allowShare" class="text-dark mx-1" @click="share()" style="cursor: pointer">
          <font-awesome-icon :icon="['fas', 'share']" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router';
import ApiClass from "@/services/api";
import {useRoute} from "vue-router";
// import {bus} from "@/services/EventBus";
export default {
  name: 'NavBar',
  data() {
    return {
      totalCart : 0,
      isLoggedIn: false,
      hash: null,
      api: null,
    }
  },
  props: ['back','title','cart','itemsOnCart','allowShare', 'profile'],
  methods: {
    goToMyProfile() {
      router.push({ name: 'myProfile', params: { hash: this.hash} });
    },
    goToLogin() {
      router.push({ name: 'login', params: { hash: this.hash} });
    },
    logout() {
      localStorage.clear();
      router.push({ name: 'home', params: { hash: this.hash} });
    },
    share() {

      if (navigator.share) {
        // Web Share API is supported

          navigator.share({
            title: 'AW MENU',
            text: 'Take a look at this product!',
            url: window.location.href
          })
            .then(() => console.log('Share successful'))
            .catch((error) => console.error('Error sharing:', error));
      } else {
          let link = encodeURIComponent(window.location.href);

          let whatsappMessage = `Take a look at this product ${link}`;

          let whatsappUrl = 'https://api.whatsapp.com/send?text=' + whatsappMessage;

          window.open(whatsappUrl, '_blank');
      }
    },
    async fetchCart() {
      try {
        let products = await this.api.fetchCart();
        this.totalCart = products.data?.result?.order.order_items.length ?? 0 ;
      } catch (error) {
        console.error('Error company product details:', error);
        this.totalCart = 0;
      }
    },
    incCartCount() {
      this.totalCart += 1;
    },
    goBack()  {
      let currentRoute =  router?.currentRoute?.value?.name;

      switch (currentRoute){
        case 'categories':
          router.push({ name: 'home', params: { hash: this.hash} });
          break;
        case 'productList':
          router.push({ name: 'categories', params: { hash: this.hash} });
          break;
        case 'orderList':
          router.push({ name: 'categories', params: { hash: this.hash} });
          break;
        default:
          router.back();
      }
      // router.back();
    },
    goToCart()  {
      router.push({ name: 'cart', params: { hash: this.hash} });
    },
    goToOrders()  {
      router.push({ name: 'orderList', params: { hash: this.hash} });
    }
  },
  created() {
    const route = useRoute();
    this.hash = route.params.hash;
    this.api = new ApiClass(this.hash);

  },
  setup(props) {
    return { props };
  }
}
</script>

<style scoped>

.btn-close:hover, .nav-link:hover, .nav-link:focus {
  background: transparent;
  color: black;
}
</style>