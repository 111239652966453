<template>
  <div id="main" class="page-background default-font">
    <div class="container-fluid p-1 pt-2 text-white text-center bg-header">
      <NavBar :profile="true" :back="true" title="Orders" :cart="false"></NavBar>
    </div>
    <div class="cart-list-container container mt-4 px-0 min-vh-100 bg-white" v-if="load && orders?.length > 0">
      <div class="">
        <div v-for="(order) in this.orders" :key="order.id" class="product-container ">

          <div class="product-details p-2 w-100">

            <div class="d-flex">
              <div @click="viewOrder(order)" class="text-limit" style="color: #26262b; font-size: 14px; font-weight: 800;cursor: pointer;">
                Order # {{ order.id }}
              </div>
              <div @click="viewOrder(order)" style="margin-left: auto">
                <span :class="'badge text-bg-'+order.status_class">{{ order.status }}</span>
              </div>
            </div>
            <div class="text-limit"
                 style="color: #26262b; font-size: 14px; font-weight: 500;">
             {{ order.createdAt}}
            </div>

            <div class="d-flex">
              <div>
                <span style="color: #2B2B2B; font-size: 12px;  font-weight: 700; word-wrap: break-word">BD</span>
                <span
                    style="padding-left: 3px;color: #2B2B2B; font-size: 20px; font-weight: 700; word-wrap: break-word"></span>
                <span
                    style="color: #2B2B2B; font-size: 16px;  font-weight: 500; word-wrap: break-word">{{ order.subTotal }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div v-else-if="load" class="container mt-4 px-0  container-fluid min-vh-100 d-flex" id="empty_cart" >
      <div class="cart-list-container bg-white row flex-grow-1">
        <div class="text-center">
          <h1>
            <font-awesome-icon :icon="['fas', 'face-frown']"/>
          </h1>

          <h5>You have not placed an order yet.</h5>
        </div>
      </div>
    </div>
    <LoadingIndicator v-else :loading="!load"></LoadingIndicator>
  </div>

</template>

<script>
import ApiClass from '@/services/api';
import NavBar from "@/components/NavBar.vue";
import LoadingIndicator from "@/components/Loading.vue";
import router from "@/router";
import {useRoute} from "vue-router";

export default {
  name: 'OrderList',
  data() {
    return {
      load: false,
      orders: [],
      timer: null
    };
  },
  components: {LoadingIndicator, NavBar},
  props: [],
  methods: {
    async reFetch() {
      await this.fetchOrders();
      this.timer = setTimeout(  () => {
         this.reFetch();
      }, 300000)
    },
    async  viewOrder(order) {
      await router.push({name:'orderDetails',params:{id: order?.id,  hash: this.hash}});

    },
    async fetchOrders() {
        try {
          let orders = await this.api.fetchOrders();

          if(!this.load) {
            this.load = true;
          }
          this.orders = orders.data?.result?.orders;

          for (const [key, order] of Object.entries(this.orders)) {
            const d = new Date(order.createdAt);
            order.createdAt = d.toLocaleString("en-US");

            switch (order.status) {
              case 'pending_approval':
                this.orders[key].status = 'Pending Approval';
                this.orders[key].status_class = 'warning';
                break;
              case 'accepted':
                this.orders[key].status = 'Accepted';
                this.orders[key].status_class = 'info';
                break;
              case 'readyToPickUp':
                this.orders[key].status = 'Ready to pickup';
                this.orders[key].status_class = 'info';
                break;
              case 'underDelivery':
                this.orders[key].status = 'Under delivery';
                this.orders[key].status_class = 'info';
                break;
              case 'delivered':
                this.orders[key].status = 'Delivered';
                this.orders[key].status_class = 'success';
                break;
              case 'completed':
                this.orders[key].status = 'Completed';
                this.orders[key].status_class = 'success';
                break;
              case 'rejected':
                this.orders[key].status = 'Rejected';
                this.orders[key].status_class = 'danger';
                break;
              case 'canceled':
                this.orders[key].status = 'Canceled';
                this.orders[key].status_class = 'danger';
                break;
              default :
                this.orders[key].status = 'Error';
                this.orders[key].status_class = 'info';
                break;
            }
          }

        } catch (error) {
          console.error('Error Orders:', error);
        }
    },
  },
  created() {
    const route = useRoute();
    this.hash = route.params.hash;
    this.api = new ApiClass(this.hash);

    this.reFetch();
  },
}
</script>

<style scoped>

@import './../assets/css/style.css';

</style>
  