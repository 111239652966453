<template>
  <div v-if="loading" class="container-loading-indicator bg-white">
    <div class="row align-items-center row-loading-indicator">
      <div class="col text-center align-middle ">
        <img  style="max-width: 50px;" src="/images/logo/spin-logo.gif" class="mt-5"  alt="loading ..."/>
        <h6 v-if="title">{{title}}</h6>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'LoadingIndicator',
    props: ['loading','title'],
  };

</script>
