<template>
    <div class="search-input input-group border-bottom">
      <span class="input-group-text border-0 pl-4" id="basic-addon1">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.16669 1.66666C13.3067 1.66666 16.6667 5.02666 16.6667 9.16666C16.6667 13.3067 13.3067 16.6667 9.16669 16.6667C5.02669 16.6667 1.66669 13.3067 1.66669 9.16666C1.66669 5.02666 5.02669 1.66666 9.16669 1.66666ZM9.16669 15C12.3892 15 15 12.3892 15 9.16666C15 5.94332 12.3892 3.33332 9.16669 3.33332C5.94335 3.33332 3.33335 5.94332 3.33335 9.16666C3.33335 12.3892 5.94335 15 9.16669 15ZM16.2375 15.0592L18.595 17.4158L17.4159 18.595L15.0592 16.2375L16.2375 15.0592Z" fill="#242424"/>
        </svg>
      </span>
      <input type="text" autocomplete="off"  id="search" @keydown="keydown" @keyup="search" class="form-control border-0" v-model="searchInput" placeholder="Search for shop or provided" aria-label="Search" aria-describedby="basic-addon1">
    </div>
</template>
<script>


export default {
  name: 'SearchBar',
  props: {
      searchItems: Function,
      text: String,
      keydownFunction: Function,
  },
  data() {
    return {
      searchInput: ''
    }
  },
  setup() {

  },
  created() {
    this.searchInput = this.text;
  },
  methods: {
    search() {
      this.searchItems(this.searchInput)
    },
    keydown() {
      if(this.keydownFunction) {
        this.keydownFunction(this.searchInput);
      }
    }
  }
}
</script>

<style scoped>

.search-input input,span {
  height: 40px;

}

.input-group-text {
  background-color: white;
}

.search-input span {
  padding-left: 20px;
  border-radius: 0 !important;
}

input::placeholder {
 text-align: left;
  font-weight: 200;
  font-size: 13px;
}

input {
  border-radius: 0 !important;
}

</style>
<script setup>
</script>