
<template>
  <main>
    <notifications position="top center" classes="notifications-light" :width="'100%'" />
    <notifications group="error" position="top center" classes="notifications-error" :width="'100%'"/>
    <router-view></router-view>
  </main>

  <FooterComponent ref="footerRef"></FooterComponent>

</template>

<style >



</style>
<script>

import FooterComponent from './components/Footer.vue';
export default {
  name: 'App',
  components: {
    FooterComponent
  }
}
</script>


