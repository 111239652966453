<template>
    <div id="main" class="page-background default-font" >
        <div class="container-fluid p-1 pt-2 text-white text-center bg-header" >
            <NavBar :profile="true" :back="true" title="Cart" :cart="false"></NavBar>
        </div>
        <div class="cart-list-container container mt-4 px-0 min-vh-100 bg-white" id="items" v-if="load && order?.order_items?.length > 0" >
            <div class="">
              <div  v-for="(orderItem,index) in this.order?.order_items" :key="orderItem.id" class="product-container " >
                <div @click="viewItem(orderItem)"  class=" col-md-2 col-5 product-image center-item p-2">
                  <img class="max-width-100 max-height-100" style="border-radius: 15px" v-bind:src="'https://api.aw-erp.com/v1/file/' + orderItem.item.featuredImage" />
                </div>
                <div class="product-details p-2 w-100">
                  <div @click="viewItem(orderItem)"  class="text-limit" style="color: #26262b; font-size: 14px; font-weight: 500;">
                    {{translate(orderItem.item.name,orderItem.item.nameL2)}}
                  </div>
                  <div class="product-price">
                    <span style="color: #2B2B2B; font-size: 12px;  font-weight: 500; word-wrap: break-word">QTY</span>
                    <span style="padding-left: 3px;color: #2B2B2B; font-size: 20px; font-weight: 700; word-wrap: break-word"></span>
                    <span style="color: #2B2B2B; font-size: 16px;  font-weight: 700; word-wrap: break-word">{{orderItem.qty}}</span>
                  </div>
                  <div class="d-flex">
                    <div>
                      <span style="color: #2B2B2B; font-size: 12px;  font-weight: 700; word-wrap: break-word">BD</span>
                      <span style="padding-left: 3px;color: #2B2B2B; font-size: 20px; font-weight: 700; word-wrap: break-word"></span>
                      <span style="color: #2B2B2B; font-size: 16px;  font-weight: 500; word-wrap: break-word">{{orderItem.subTotal}}</span>
                    </div>
                    <div style="margin-left: auto">
                        <button @click="removeFromCart(orderItem.id,index)" class="btn-add-to-cart" style="padding: 3px 15px;background: #e5e5e6; border-radius: 50px;text-align: center; color: #26262b; font-size: 12px; font-weight: 700; word-wrap: break-word;border:0">
                          <font-awesome-icon :icon="['fas', 'xmark']" />
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button has-selected" :class="{'show': shipping === '','collapsed':shipping}" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Shipping <span v-if="shipping !== ''" class="selected-option">{{shipping}}</span>
                  </button>

                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse " :class="{'show': shipping === '','collapse':shipping}" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div class="row mx-3 ">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" v-model="shipping" id="shipping2" value="delivery">
                          <label class="form-check-label" for="shipping2">
                            Delivery
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">

                  <h2 class="accordion-header">
                    <button class="accordion-button has-selected" type="button" :class="{'collapsed':true}" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                      Payment method  <span v-if="paymentMethod !== ''" class="selected-option">{{paymentMethod}}</span>
                    </button>
                  </h2>

                  <div id="flush-collapse4" class="accordion-collapse" :class="{'collapse':true}" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      <div class="row mx-3" >
                        <div class="form-check">
                          <input class="form-check-input" type="radio" v-model="paymentMethod" id="paymentCash" value="cash">
                          <label class="form-check-label" for="PaymentCash">
                            Cash
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="accordion-item" v-if="shipping === 'delivery'">
                  <h2 class="accordion-header">
                    <button class="accordion-button" type="button" :class="{'show': address === '','collapsed':address}" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                      Address
                      <span v-if="address != null" class="selected-option">{{address.aliasName}}</span>
                    </button>
                  </h2>
                  <div id="flush-collapse3" class="accordion-collapse" :class="{'show': address === '','collapse':address}" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body px-0">
                      <div class="row mx-3 " >

                        <div class="form-check" v-for="a in addressList"  :key="a.id" @click="setAddress(a)">
                          <input class="form-check-input" type="radio" v-model="addressId" :id="a.id" :value="a.id" required>
                          <label class="form-check-label" :for="a.id">
                            {{ a.aliasName }}
                          </label>
                        </div>

                      </div>
                      <div class="row mt-3 px-3 " style="background: #e5e5e65c;" >
                        <button @click="newAddress" type="button" class="btn w-100 text-start px-4"  style="border-radius: 0;">
                          <font-awesome-icon :icon="['fas', 'plus']" class="mx-2" /> New Address
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

                <div>
                  <h2  style="    color: #26262b;background-color: #e5e5e6;">
                    <span class="cart-header">
                      Total
                    </span>
                  </h2>
                </div>

                <div id="calc" class="bg-white w-100 p-4" style="overflow:hidden">
                  <div class="col-12">
                    <div class="row p-2  border-bottom">
                      <div class="col-4"><b>Sub Total</b></div>
                      <div class="col-8  text-end"><span style="color: #2B2B2B; font-size: 12px;  font-weight: 700; word-wrap: break-word">BD</span> {{this.order.subTotal}}</div>
                    </div>
                    <div class="row p-2 border-bottom">
                      <div class="col-4"><b>Tax</b></div>
                      <div class="col-8  text-end"><span style="color: #2B2B2B; font-size: 12px;  font-weight: 700; word-wrap: break-word">BD</span> {{this.order.tax}}</div>
                    </div>
                    <div class="row p-2">
                      <div class="col-4"><b>Total</b></div>
                      <div class="col-8  text-end"><span style="color: #2B2B2B; font-size: 12px;  font-weight: 700; word-wrap: break-word">BD</span> {{this.order.total}} </div>
                    </div>
                  </div>
                </div>
                <div class="bg-white ">
                  <div class="row mx-3 pt-4">
                    <button @click="submitOrder" class="btn btn-primary my-2 px-3 mb-4 aw-button" :class="{'disabled': !(shipping === 'pickup' || ( (shipping === 'delivery' &&  addressId) ))}">Check out</button>
                  </div>
                </div>
              </div>
        </div>

        <div class="container mt-4 px-0  container-fluid min-vh-100 d-flex" id="empty_cart" v-else-if="load">

          <div class="cart-list-container bg-white row flex-grow-1">
            <div class="text-center">
              <h1><font-awesome-icon :icon="['fas', 'face-frown-open']" /></h1>
              <h5>Your cart is empty</h5>
            </div>
          </div>
        </div>
        <LoadingIndicator :loading="!load" ></LoadingIndicator>

    </div>
       
  </template>
  
  <script>
  import ApiClass from '@/services/api';

  import router from '@/router';
  import NavBar from "@/components/NavBar.vue";
  import LoadingIndicator from "@/components/Loading.vue";
  import {useRoute} from "vue-router";

  export default {
    name: 'CartList',
    data() {
      return {
        order: {},
        shipping: 'delivery',
        paymentMethod: 'cash',
        addressList: [],
        addressId: null,
        address: null,
        load: false,
        hash: null,
        api: null,
      };
    },
    components: {LoadingIndicator, NavBar},
    props: [],
    methods: {
      setAddress(address) {
        this.address = address;
      },
      async removeFromCart(id,key) {
        try {
          console.log(`Adding Item ${id}`)
          let res = await this.api.removeFromCart(id);
          if(res) {
            this.order.order_items.splice(key,1)
          }
        } catch {
          console.error('Error removing item from your cart');
        }
      },
      newAddress() {
        router.push({ name: 'newAddress', params: { hash: this.hash } });
      },
      async fetchCart() {
        try {
          let products = await this.api.fetchCart();
          this.order = products.data?.result?.order;
          this.load = true;

        } catch (error) {
          console.error('Error company product details:', error);
          this.load = true;

        }
      },
      async fetchAddress(selectLastAddress = false) {
        try{
          let response = await this.api.fetchAddress();

          if(response?.status == '200') {
            this.addressList  = response.data?.result?.addressList;

            if(selectLastAddress && this.addressList?.length > 0 ) {
              let lastAddress = this.addressList[this.addressList.length - 1];
              this.setAddress(lastAddress);
              this.addressId = lastAddress.id;
            }
          }
        }
        catch(e){
          console.error(e);
        }
      },
      async submitOrder() {
        try{

          let response = await this.api.checkout(this.addressId);

          console.log(response);
          if(response?.status == '200') {
            this.$notify({
              title: "Order Placed",
              text: `Thank you`,
            });


            await router.push({name:'orderDetails',params:{id: parseInt(response?.data?.result?.orderNo),  hash: this.hash}});
          }
        }
        catch(e){
          console.error(e);
        }
      },
    },
    created(){
      const route = useRoute();
      this.hash = route.params.hash;
      this.api = new ApiClass(this.hash);

      this.fetchCart();

      const selectLastAddress =  () => {
        const backUrl = router.options.history.state.back
        let last =  router.resolve({ path: `${backUrl}` })

        if(last) {
          return last.name === 'newAddress';
        }
        return false;
      };

      this.fetchAddress(selectLastAddress());

      console.log(router.options.history.state);
    },
    setup() {
        const lang = 'en';

        const translate = (enVar, arVar) => {
            if(lang === 'ar' && arVar != null) 
                return arVar;
            return enVar; 
        };

        const viewItem = (item) => {
          router.push({ name: 'productDetails', params: { hash: this.hash, id: item?.id } });
        }
        const goBack = () => {
            router.back();
        }

        return { 
            translate,
            viewItem,
            goBack,
        };
    },
  }
  </script>
  
  <style scoped>

    @import './../assets/css/style.css';

    .accordion-button.has-selected::after {
      margin-left: 10px;
    }
   
    .item-page-background {
        background: #F2F2F2;
        height: 100vh;
        font-family: 'MADE TOMMY', 'Cairo',sans-serif;
    }

    .search {
        background: rgba(176, 176, 182, 0.20);
        border-radius: 50px;
        height: 46px;
        position: relative;
        border:0;
        margin-left: 0;
        padding-left: 18px;;
        
    }

    .rectangle-3, .rectangle-3 * {
        box-sizing: border-box;
    }

    .rectangle-3 {
    background: var(--blurgrey, #f0f0f0);
     border-radius: 10px;
     width: 335px;
     height: 1px;
     position: absolute;
     left: 29px;
    top: 365px;
    }

    .accordion-button:not(.collapsed) {
      color: #26262b;
      background-color: #e5e5e6;
      box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }

    .accordion-button:focus {
      box-shadow: 0 0 0 0.25rem rgb(38 38 43 / 7%) !important;
    }

    .form-check-input:checked {
      background-color: #26262b;
      border-color: #26262b;
    }

    .btn-primary {
      --bs-btn-color: #fff;
      --bs-btn-bg: #26262b;
      --bs-btn-border-color: #26262b;
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: #26262b;
      --bs-btn-hover-border-color: #26262b;
      --bs-btn-focus-shadow-rgb: 49, 132, 253;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: #26262b;
      --bs-btn-active-border-color: #26262b;
      --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: #fff;
      --bs-btn-disabled-bg: #26262b;
      --bs-btn-disabled-border-color: #26262b;
    }

    .btn:disabled, .btn.disabled, fieldset:disabled .btn {
      color: var(--bs-btn-disabled-color);
      pointer-events: none;
      background-color: #e5e5e6;
      border-color: #e5e5e6;
      opacity: var(--bs-btn-disabled-opacity);
    }

    .selected-option {
      text-transform: capitalize;
      //background: #26262b;
      //color: #e5e5e6;
      padding: 5px 20px;
      border-radius: 50px;
      margin-left: auto;
      font-size: 12px;
      font-weight: 400;
    }

    .accordion-button:hover {
      background-color: transparent;
      color: #1a1d20;
    }

    .cart-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
      font-size: 1rem;
      border: 0;
      background: #e5e5e6;
      font-weight: 700;
    }
  </style>
  