<template>
  <footer id="footer" class="footer mt-auto text-white border-top" v-if="!hide">
    <div class="container">
      <div class="row">
        <div class="col-3">
          <div class="center-flex">
            <button  class="rounded-icon-lg mx-2" @click="goToHome()">
              <font-awesome-icon :icon="['fas', 'home']" size="lg" />
            </button>
          </div>
        </div>

        <div class="col-3">
          <div class="center-flex">
            <button  class="rounded-icon-lg mx-2 text-center position-relative"  @click="goToCart()">
              <font-awesome-icon :icon="['fas', 'cart-shopping']" size="lg"  />
              <span v-if="totalCart"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                <span>{{ totalCart || itemsOnCart }}</span>
                <span class="visually-hidden">cart items</span>
              </span>
            </button>
          </div>
        </div>

        <div class="col-3">
          <div class="center-flex">
            <button  class="rounded-icon-lg mx-2 text-center" @click="goToMyProfile()">
              <font-awesome-icon :icon="['fas', 'user']" size="lg"  />
            </button>
          </div>
        </div>

        <div class="col-3 ">
          <div class="center-flex">
            <button  class="rounded-icon-lg mx-2 " data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <font-awesome-icon :icon="['fas', 'bars']" size="lg"  />
            </button>
          </div>
        </div>

      </div>
    </div>
  </footer>
  <div v-if="hide" class="clear-body-margin-bottom " />


  <div class="text-start offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasNavbarLabel">{{phone}}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li class="nav-item">
          <button class="nav-link" data-bs-dismiss="offcanvas" v-if="isLoggedIn"  @click="goToMyProfile()">My Profile</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" data-bs-dismiss="offcanvas" v-if="isLoggedIn" @click="goToOrders()">My Orders</button>
        </li>
        <hr>
        <li class="nav-item">
          <button class="nav-link" data-bs-dismiss="offcanvas"  v-if="isLoggedIn" @click="logout" >Logout</button>
        </li>
        <li class="nav-item">
          <button class="nav-link"  data-bs-dismiss="offcanvas" v-if="!isLoggedIn" @click="goToLogin" ><font-awesome-icon  :icon="['fas', 'arrow-right-to-bracket']" /> Login</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import router from '@/router';
import ApiClass from "@/services/api";
import {useRoute} from "vue-router";
export default {
  name: 'FooterComponent',
  data() {
    return {
      hide: false,
      totalCart : 0,
      isLoggedIn: false,
      phone: '',
      api: null,
      hash: null,
    }
  },
  props: {
    back: Boolean,
    title: Boolean,
    cart: Boolean,
    allowShare: Boolean,
    profile: Boolean,
    itemsOnCart: Boolean,
  },
  methods: {
    goToMyProfile() {
      router.push({ name: 'myProfile', params: { hash: this.hash} });
    },
    goToLogin() {
      router.push({ name: 'login', params: { hash: this.hash} });
    },
    logout() {
      localStorage.clear();
      router.push({ name: 'home', params: { hash: this.hash} });
    },
    goToHome() {
      router.push({ name: 'home', params: { hash: this.hash} });
    },
    share() {
      if (navigator.share) {
        // Web Share API is supported

          navigator.share({
            title: 'AW MENU',
            text: 'Take a look at this product!',
            url: window.location.href
          })
            .then(() => console.log('Share successful'))
            .catch((error) => console.error('Error sharing:', error));
      } else {
          let link = encodeURIComponent(window.location.href);

          let whatsappMessage = `Take a look at this product ${link}`;

          let whatsappUrl = 'https://api.whatsapp.com/send?text=' + whatsappMessage;

          window.open(whatsappUrl, '_blank');
      }
    },
    async fetchCart() {
      try {
        let products = await this.api.fetchCart();
        this.totalCart = products.data?.result?.order.order_items.length ?? 0 ;
      } catch (error) {
        console.error('Error company product details:', error);
        this.totalCart = 0;
      }
    },
    goToCart()  {
      router.push({ name: 'cart', params: { hash: this.hash} });
    },
    goToOrders()  {
      router.push({ name: 'orderList', params: { hash: this.hash} });
    }
  },
  watch: {
    '$route' (currentRoute){
      this.hide = ['home','cart','myProfile','orderList','orderDetails','newAddress','productDetails', 'login', 'loginOtp'].includes(currentRoute.name);
    }
  },
  mounted(){

    this.isLoggedIn =  localStorage.getItem('isAuthenticated');
    this.phone = localStorage.getItem('nationalNumber');

    const route = useRoute();
    this.hash = route.params.hash;
    this.api = new ApiClass(this.hash);

    if(this.props.cart)
    {
      this.fetchCart();
    }

  },
  setup(props) {
    return { props };
  }
}
</script>


