import {createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import "bootstrap"

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Notifications from '@kyvg/vue3-notification'

library.add(fas);


createApp(App)
    .use(router)
    .use(Notifications)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component("page-not-found", {
        template: "",
        created: function() {
            // Redirect outside the app using plain old javascript
            window.location.href = "/_errors/404.html";
        }
    })
    .mount('#app');

