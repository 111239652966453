<template>
  <!-- temp -->
  <nav class="navbar bg-dark text-white">
    <div class="container-fluid ">
      <a class="text-white text-decoration-none" href="#">AW Menu</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
        <span class="text-white
        navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>
          </ul>
          <form class="d-flex mt-3" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit">Search</button>
          </form>
        </div>
      </div>
    </div>
  </nav>
  <!-- end temp -->

  <SearchBar></SearchBar>
  <div class="container bg-white">
    <div class="pt-3 row align-items-center justify-content-between">
      <div class="col-10">
        <h3 class="fw-lighter">All Shops</h3>
      </div>
      <div class="col text-end">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#212529">
          <path
              d="M462-125v-216h54v81h317v54H516v81h-54Zm-336-81v-54h224v54H126Zm170-166v-81H126v-54h170v-81h54v216h-54Zm166-81v-54h371v54H462Zm147-166v-216h54v81h170v54H663v81h-54Zm-483-81v-54h371v54H126Z"/>
        </svg>
      </div>
    </div>

    <div v-if="providers && providers.length>0">
      <div @click="viewProvider(provider.hash)" v-for="provider of providers" :key="provider.id" class="row border-bottom align-items-center py-3">
          <div class="col-md-2 col-3">
            <img class="img-thumbnail rounded"
                 v-bind:src="'https://api.aw-erp.com/v1/file/' + provider.logo"/>
          </div>
          <div class="col">
            <div class="text-limit">
              {{ provider.name }}
            </div>
            <p class="text-limit fw-lighter m-0">
              {{ provider.aw_companies_sectors[0].title }}
            </p>
          </div>
      </div>

    </div>

    <LoadingIndicator :loading="!load"></LoadingIndicator>

    <div v-if="load && (!providers || providers.length < 1)" class="p-3 bg-white text-center">
      <h6>no result found!</h6>
    </div>
  </div>

</template>

<script>
import {fetchProviders} from '@/services/api';
import SearchBar from "@/components/SearchBar.vue";
import router from "@/router";
import LoadingIndicator from "@/components/Loading.vue";

export default {
  name: 'ProvidersList',
  components: {LoadingIndicator, SearchBar},
  data() {
    return {
      providers: [],
      load: Boolean
    }
  },
  methods: {
    async loadProviders() {
      try {
        let providers = await fetchProviders();
        this.providers = providers.data?.result;
        console.log(providers)

      } catch (error) {
        console.error('Error', error);
      }
    },
    viewProvider(hash) {
       router.push({path:'/provider/',query:{ hash: hash}});
    },
  },
  created() {
    this.loadProviders();
  },
  setup() {},
}
</script>

<style scoped>

@import './../assets/css/style.css';

</style>
  