<template>
  <div class="container-error">
      <div class="middle">
        <h1 class="mb-3">404 | Page Not Found</h1>
        <hr>
        <p class="mt-3">Please check your url</p>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {}
  },
  props: {
  },
}
</script>

<style scoped>

.container-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

.middle {
  text-align: center;
}

hr {
  margin: auto;
  width: 40%;
}

</style>