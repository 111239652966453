import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";

export default class ApiClass {
    constructor(selectedHash) {
        this.hash = selectedHash;
        this.apiURL = 'https://api.aw-erp.com/v1/eMenu';

        this.config = {
            headers:{
                hash: this.hash ,
            }
        };

        this.api = axios.create({
            baseURL: this.apiURL,
        });

        this.api.interceptors.request.use( (config) => {
            const token = localStorage.getItem('authToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config;
        });

        this.api.interceptors.response.use((response) => {
            return response
        }, (error) => {
            const respCode = error.response?.data?.respCode ?? '500';
            const respDesc = error.response?.data?.respDesc ?? 'oops something went wrong';
            if (error.response) {
                notify({
                    title: respCode,
                    text: respDesc,
                    group:"error",
                });
            }
            return false
        });
    }
    async fetchCompany () {
        console.log('company');
        return await this.api.get('/company',this.config)
    }

    async fetchCategories () {
        return await this.api.get('/categories',this.config)
    }

    async fetchProducts (categoryId, search, tags) {
        categoryId = categoryId == null ? '' : categoryId;
        return await this.api.get(`/items?category=${categoryId}&search=${search}&tags=${tags}`,this.config);
    }

    async fetchProviders () {
        return await this.api.get(`/providers`,this.config);
    }

    async fetchCart () {
        return await this.api.get(`/cart`,this.config);
    }

    async fetchAddress () {
        return await this.api.get('/addressList', this.config);
    }

    async saveAddress(address){
        return await this.api.post(`/address/`, address, this.config)
    }


    async fetchProfile () {
        return await this.api.get('/profile', this.config);
    }

    async updateProfile(profileData){
        return await this.api.put('/profile', profileData ,this.config)
    }

    async fetchProductDetails(productId){
        return await this.api.get(`/item/${productId}`,this.config);
    }

    async registerCustomer(nationalNumber,dialingCode){
        return await this.api.post(`/register/`, {mobile: nationalNumber, dialingCode: dialingCode}, this.config)
    }

    async validateOTP(nationalNumber,dialingCode,otp){
        return await this.api.post(`/register/`, {mobile: nationalNumber, dialingCode: dialingCode,otp: otp}, this.config)
    }

    async addToCart(id,qty,note) {
        let items = [];
        items.push({id: id, qty: qty,note: note});
        return await this.api.post(`/cart/`, {items:items}, this.config)
    }
    async removeFromCart (id){
        return await this.api.delete(`/cart/${id}`,this.config)
    }

    async checkout (addressId){
        return await this.api.post('/order', {addressId:addressId},this.config);
    }

    async fetchOrders () {
        return await this.api.get(`/orders`,this.config);
    }

    async fetchOrder (orderId){
        return await this.api.get(`/order/${orderId}`,this.config);
    }

}

// const queryParams = new URLSearchParams(location.search);
// let hash =  queryParams.get('hash');

// if(hash != localStorage.getItem('hash')) {
//   localStorage.setItem('hash', hash);
// }

// let noHash = ['/404','/providers'];

// if(!hash && (!noHash.includes(window.location.pathname)) ) {
//     window.location.replace('/404');
// }

// let hash = 'M2QzNWZkYTRkNGQ1OGJkODM3NWE5NjgwMDkzMWE5NTIyYWEyZjgxMmJhYzkzYTFmZDIyNDBkYjllZThjZWM2MQ==';





